<template>
    <div v-if="form" class="flex border-b border-gray-200 dark:border-gray-700 pb-6">
      <div class="w-full grid grid-cols-12">

        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <item-text title="Prece" :text="item.name" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <item-text title="Kategorija" :text="item.category.name" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <item-text title="Preces kods" :text="item.sku" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <item-text title="Noliktavas atlikums" :text="item.quantity_in_selected_warehouse" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <div class="w-40">
            <Input type="number" placeholder="Skaits" v-model="form.quantity" :errors="errors[`quantity-${item.id}`] ? errors[`quantity-${item.id}`] : [form.errorMessage]" @change="checkQuantity" />
          </div>
        </div>

      </div>
      <div class="ml-auto">
        <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
          <Button icon="close" @click="$emit('remove-product', item)" />
        </p>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText";
import Input from "@/components/Components/Input";

export default {
  name: "SingleStockMovementItemForm",
  props: {
    item: {},
  },
  components: {
    ItemText,
    Input,
  },
  data: () => ({
    form: null,
    warehouse: null,
  }),
  mounted() {
    this.form = this.item
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      warehouses: "warehousesList",
      errors: "errors"
    }),
  },
  emits: ['item'],
  methods: {
    checkQuantity() {
      if(+this.form.quantity_in_selected_warehouse < +this.form.quantity) {
        this.form.errorMessage = 'Nepietiekams atlikums'
      } else if (+this.form.quantity < 1) {
        this.form.errorMessage = 'Daudzums mazāks par 1'
      } else this.form.errorMessage = ''
        
      this.$emit("item", this.form)
    },
  }
}
</script>

<style>

</style>